import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonIcon from '@mui/icons-material/Person';
import {
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import ButtonIcon from 'components/ButtonIcon';
import CustomTableCell from 'components/CustomTableCell';
import { CustomTableFontCell } from 'components/CustomTableFontCell';
import Restrict from 'components/Restrict';
import Spinner from 'components/Spinner';
import { TypographyLocale } from 'components/TypographyLocale';
import UseAuthContext from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  canclebyInvitationID,
  removeStaffbyInvitationID,
  resendInvitation,
} from 'services/business-setting-service';
import { user_authorizations } from 'utils/Formatter';
import { getErrorHtmlContent } from 'utils/utils';
import MemberInviteForm from './MemberInviteForm';

const BusinessStaff = ({ refetch, dataOrganisation }: any) => {
  const {
    auth,
    getOrganisation,
  } = useContext(UseAuthContext);
  const { language } = useContext(UseLanguageContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElInvite, setAnchorElInvite] = useState(null);
  const open = Boolean(anchorEl);
  const openInvite = Boolean(anchorElInvite);
  const [isLoading, setisLoading] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const roles = [
    {
      title: t('BUSINESS_SETTING_MEMBER_PERMISSION_MANAGER'),
      description: t('BUSINESS_SETTING_MEMBER_PERMISSION_MANAGER_DESCRIPTION'),
    },
    {
      title: t('BUSINESS_SETTING_MEMBER_PERMISSION_MEMBER'),
      description: t('BUSINESS_SETTING_MEMBER_PERMISSION_MEMBER_DESCRIPTION'),
    },
    {
      title: t('BUSINESS_SETTING_MEMBER_PERMISSION_VIEWER'),
      description: t('BUSINESS_SETTING_MEMBER_PERMISSION_VIEWER_DESCRIPTION'),
    },
  ];

  const handleMouseEnter = (id: string) => {
    setHoveredRow(id);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  
  const handleClick = (event: any, id: string) => {
    setSelectedId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickInvite = (event: any, id: string) => {
    setSelectedId(id);
    setAnchorElInvite(event.currentTarget);
  };

  const handleCloseInvite = () => {
    setAnchorElInvite(null);
  };

  const resendInvite = async (id: any) => {
    try {
      setisLoading(true);
      await resendInvitation(id);
      setisLoading(false);
      enqueueSnackbar(t('BUSINESS_STAFF_INVITE_RESEND_SUCESS'), {
        variant: 'success',
      });
      handleCloseInvite();
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  const cancelInvite = async (id: any) => {
    try {
      setisLoading(true);
      await canclebyInvitationID(id);
      await getOrganisation(dataOrganisation?.id);
      refetch();
      setisLoading(false);
      enqueueSnackbar(t('BUSINESS_STAFF_INVITE_CANCEL_SUCESS'), {
        variant: 'success',
      });
      handleCloseInvite();
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  const removeFromStaff = async (id: any) => {
    try {
      
      setisLoading(true);
      await removeStaffbyInvitationID(id);
      await getOrganisation(dataOrganisation?.id);
      refetch();
      setisLoading(false);
      enqueueSnackbar(t('BUSINESS_STAFF_REMOVE_SUCESS'), {
        variant: 'success',
      });
      handleClose();
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner></Spinner>;
  }


  return (
    <Grid >
      <Grid item className='business_staff_grid'>
        <Restrict forbidden={["viewer", "member"]}>
          <MemberInviteForm members={dataOrganisation?.members} refetch={refetch} />
        </Restrict>
      </Grid>
      <Box className="business_staff_grid">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  "&:hover": {
                    boxShadow: "none",
                    border: "none",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <CustomTableFontCell>
                  {t("BUSINESS_SETTING_MEMBER_PERMISSION_NAME")}
                </CustomTableFontCell>
                <CustomTableFontCell>
                  {t("BUSINESS_SETTING_MEMBER_PERMISSION_EMAIL")}
                </CustomTableFontCell>
                <CustomTableFontCell>
                  {t("BUSINESS_SETTING_MEMBER_PERMISSION_ROLE")}
                </CustomTableFontCell>
                <Restrict forbidden={["viewer", "member"]}>
                  <TableCell></TableCell>
                </Restrict>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataOrganisation?.members?.length > 0 ? (
                dataOrganisation?.members?.map((item: any) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={`members${item.id}`}
                      onMouseEnter={() => handleMouseEnter(item.id)}
                      onMouseLeave={handleMouseLeave}
                    >

                      <CustomTableCell clickable={false} >{item.name}</CustomTableCell>
                      <CustomTableCell clickable={false} >{item.email}</CustomTableCell>
                      <CustomTableCell clickable={false} >{t(user_authorizations.find(auth => auth.value === item.authorization)?.label)}</CustomTableCell>
                      <Restrict forbidden={["viewer", "member"]}>
                        <CustomTableCell clickable={false}>
                          {auth && auth?.id === item.id ? (
                            <></>
                          ) : (
                            <>
                              <ButtonIcon
                                isActive={open && selectedId === item.id}
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, item.id)}
                              >
                                <MoreHorizIcon />
                              </ButtonIcon>
                              <Menu
                                sx={{ fontSize: '14px !important' }}
                                id="long-menu1"
                                MenuListProps={{
                                  "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEl}
                                open={open && selectedId === item.id}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  key="1"
                                  onClick={() => removeFromStaff(item.id)}
                                >
                                  {t("BUSINESS_SETTING_MEMBER_REMOVE_FROM_STAFF")}
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </CustomTableCell>
                      </Restrict>
                    </TableRow>
                  );
                })
              ) : (
                <></>
              )}

              {dataOrganisation?.invites?.length > 0 ? (
                dataOrganisation?.invites?.map((item) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={`members${item.id}`}
                      onMouseEnter={() => handleMouseEnter(item.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <CustomTableCell clickable={false} className="disable_font_color">
                        {t("INVITED_BY_TEAM")}
                      </CustomTableCell>
                      <CustomTableCell clickable={false} className="disable_font_color">
                        {item.email}
                      </CustomTableCell>
                      <CustomTableCell clickable={false} className="disable_font_color">
                        {t(user_authorizations.find(auth => auth.value === item.authorization)?.label)}
                      </CustomTableCell>

                      <Restrict forbidden={["viewer", "member"]}>
                        <CustomTableCell clickable={false} className="disable_font_color">
                          <>
                            <ButtonIcon
                              aria-controls={openInvite ? "long-menu" : undefined}
                              aria-expanded={openInvite ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => handleClickInvite(e, item.id)}
                            >
                              <MoreHorizIcon />
                            </ButtonIcon>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorElInvite}
                              open={openInvite && selectedId === item.id}
                              onClose={handleCloseInvite}
                              sx={{ fontSize: '14px !important' }}
                            >
                              <MenuItem
                                key="3"
                                onClick={() => resendInvite(item.id)}
                              >
                                {t("BUSINESS_SETTING_MEMBER_RESEND_INVITE")}
                              </MenuItem>
                              <MenuItem
                                key="4"
                                onClick={() => cancelInvite(item.id)}
                              >
                                {t("BUSINESS_SETTING_MEMBER_CANCEL_INVITE")}
                              </MenuItem>
                            </Menu>
                          </>
                        </CustomTableCell>
                      </Restrict>
                    </TableRow>
                  );
                })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>{" "}
        </TableContainer>

      </Box>
      <Grid sx={{ mt: 5 }}>
        <TypographyLocale
          language={language}
          variant="h3">{t('BUSINESS_SETTING_MEMBER_PERMISSION')}</TypographyLocale>
        <List>
          {roles.map((role, index) => (
            <ListItem key={index} sx={{ display: 'flex', gap: "10px", px: 0 }}>
              <PersonIcon />
              <Typography variant="subtitle1">
                <strong>{role.title} : </strong> {role.description}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default BusinessStaff;
