import { Box } from '@mui/material';

const HowItWorks = () => {
  let source = process.env.REACT_APP_WP_HOW_IT_WORKS;

  return (
    <Box >
      <iframe
        src={source}
        width='100%'
          height='6004px'
        style={{ border: 'none', overflow: 'hidden', scrollbarWidth: 'none' }}
      ></iframe>
    </Box>
  );
};

export default HowItWorks;
