import { Box, CircularProgress, Grid, TextField } from '@mui/material';
import ButtonCustom from 'components/ButtonCustom';
import Restrict from 'components/Restrict';
import UseAuthContext from 'context/AuthContext';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateOrganisation } from 'services/business-setting-service';
import { USER_ROLES } from 'utils/constants';
import { getErrorHtmlContent } from 'utils/utils';

const BusinessContact = ({ refetch, organizations }: any) => {
  const { t } = useTranslation();
  const { setOrganisation } = useContext(UseAuthContext);
  const [phone, setPhone] = useState(organizations?.company_phone);
  const [email, setEmail] = useState(organizations?.company_email);
  const [isLoading, setisLoading] = useState(false);
  const { role } = useContext(UseAuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const readOnly = useMemo(() => {
    return role &&
      role !== USER_ROLES.ADMIN &&
      ![USER_ROLES.MANAGER].includes(role)
      ? true
      : false;
  }, []);


  const onClickHandler = async (e: any) => {
    try {
      setisLoading(true);
      e.preventDefault();
      const data = {
        image_url: organizations?.image_url,
        company_phone: phone,
        company_name: organizations.company_name,
        company_email: email,
        id: organizations?.id,
      };
      const response = await updateOrganisation(data);
      if (response.status === 200) {
        setOrganisation(response.data);

        enqueueSnackbar(t('BUSINESS_CONTACT_INFO_UPDATE_SUCESS'), {
          variant: 'success',
        });
        refetch();
        setisLoading(false);
      }
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  return (
    <Box component='form' sx={{ width: { xs: "350px", sm: "480px" }}} className='tab-content-form'>
      <TextField
        
        fullWidth
        variant='standard'
        id='email'
        name='email'
        type='email'
        autoFocus
        label={t('EDIT_BUSINESS_SETTING_EMAIL')}
        autoComplete='off'
        autoCorrect='off'
        helperText={t('EDIT_BUSINESS_SETTING_EMAI_HELPER_TEXT')}
        defaultValue={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={readOnly}
      />
      <TextField
        
        fullWidth
        variant='standard'
        id='phone'
        name='phone'
        type='phone'
        label={t('EDIT_BUSINESS_SETTING_PHONE')}
        autoComplete='off'
        autoCorrect='off'
        helperText={t('EDIT_BUSINESS_SETTING_PHONE_HELPER_TEXT')}
        defaultValue={phone}
        onChange={(e) => setPhone(e.target.value)}
        disabled={readOnly}
      />
      <Restrict forbidden={['viewer', 'member']}>
      <Grid textAlign={"justify"}>
        <ButtonCustom
          color='primary'
          type='submit'
          variant='contained'
          onClick={onClickHandler}
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
        >
          {t('SAVE_BUTTON')}
        </ButtonCustom>
        </Grid>
      </Restrict>
    </Box>
  );
};

export default BusinessContact;
