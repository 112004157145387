import { Box, Typography } from '@mui/material';
import GenericDialog from 'components/GenericDialog';
import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import SplashScreen from 'pages/SplashScreen';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { deleteProject, getProjectLists } from 'services/project-service';
import { getErrorHtmlContent } from 'utils/utils';
import ProjectFilter from './ProjectFilter';
import ProjectGridView from './ProjectGridView';
import ProjectListView from './ProjectListView';

const ProjectPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = useState('updatedAt');
  
  const { data: dataProjectList, isLoading, refetch } = useQuery(
    ['GetProjectsList', page, pageSize, sortOrder, sortBy, searchValue],
    () => getProjectLists({
      page,
      pageSize,
      sortOrder,
      sortBy,
      search: searchValue
    }),
    {
      keepPreviousData: true,
      staleTime: 0,      
      cacheTime: 30 * 60 * 1000,    // 30 minutes
      refetchOnWindowFocus: true,      
    }
  );

  const { t } = useTranslation();
  const [view, setview] = useState("Grid");
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<string | null>(null);
  const { businessId } = useParams();

  useEffect(() => {
    if (businessId) {
      localStorage.setItem('organizationId', businessId);
    }
  }, [businessId]);

  const handleSearch = debounce((value: string) => {
    setSearchValue(value);
    setPage(1);
  }, 500);

  const handleClearSearch = () => {
    setSearchValue("");
    setPage(1);
  };

  const handleSort = (field: string) => {
    setSortBy(field);
    setSortOrder(current => current === 'asc' ? 'desc' : 'asc');
  };

  const changeView = useCallback(
    (view: any) => {
      setview(view);
    },
    [view]
  );

  const deleteProjecthandler = async (e: any, projectId: any) => {
    try {
      const res = await deleteProject(projectId);
      if (res.status === 200) {
        enqueueSnackbar(t('PROJECT_DELETE_SUCCESS'), {
          variant: 'success',
        });
        refetch();
      }
    } catch (error: any) {
      debugger;
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
    }
    setOpenDeletePopUp(false);
    setProjectToDelete(null);
  };

  const handleOpenDeleteDialog = (e: any, projectId: string) => {
    e.preventDefault();
    e.stopPropagation();
    setProjectToDelete(projectId);
    setOpenDeletePopUp(true);
  };
  return (
    <>
      <ProjectFilter
        changeView={changeView}
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
        view={view}
      />
      {isLoading ? (
        <SplashScreen />
      ) : dataProjectList?.data && dataProjectList.data?.length > 0 ? (
        view === "Grid" ? (
          <ProjectGridView
            data={dataProjectList.data}
            pagination={dataProjectList.paging}
            page={page}
            pageSize={pageSize}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            refetchingData={refetch}
            deleteProjecthandler={handleOpenDeleteDialog}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={handleSort}
          />
        ) : (
          <ProjectListView
            data={dataProjectList.data}
            pagination={dataProjectList.paging}
            page={page}
            pageSize={pageSize}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={handleSort}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            refetchingData={refetch}
            deleteProjecthandler={handleOpenDeleteDialog}
          />
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <img
            src={require("../../assets/NoProject.svg").default}
            style={{ marginBottom: "48px" }}
            alt=""
          />
          <Typography variant="h5" fontWeight="bold" fontSize="24px">
            {searchValue.length < 1
              ? t("NO_PROJECT_HEADING_1")
              : t("NO_SEARCHRESULT_HEADING_1")}
          </Typography>
          {/* <Typography variant="h5" fontSize="18px">
            {searchValue.length < 1 ? t("NO_PROJECT_HEADING_2"): ""}
          </Typography> */}
        </Box>
      )}
      <GenericDialog
        open={openDeletePopUp}
        onClose={() => {
          setOpenDeletePopUp(false);
          setProjectToDelete(null);
        }}
        title={t("PROJECT_DELETE_POPUP_TITLE")}
        onConfirm={(e) => deleteProjecthandler(e, projectToDelete)}
        confirmButtonText={t("DELETE_BUTTON")}
        cancelButtonText={t("CANCEL_BUTTON")}
        isLoading={false}
      >
        {t("PROJECT_DELETE_POPUP_DESCRIPTION")}
      </GenericDialog>
    </>
  );
};

export default ProjectPage;