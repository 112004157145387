import { AuthenticationLayout } from 'components/AuthenticationLayout';
import ProtectedRoute from 'components/ProtectedRoute';
import AdminDashboard from 'pages/Admin/AdminDashboard';
import InviteSignUp from 'pages/InviteSignUp';
import SignUP from 'pages/SignUP';
import SignUpByInvite from 'pages/SignUpByInvite';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { USER_ROLES } from 'utils/constants';
import { Layout } from './components/Layout';
import BusinessAccountPage from './pages/BusinessAccount/EditBusinessAccount';



export const routePaths = {
  home: '',
  login: 'login',
};

// Composants chargés immédiatement (sans lazy loading)
import AdminLoginPage from './pages/AdminLogin';
import EditBusinessPage from './pages/BusinessAccount/EditBusinessAccount';
import ContactUsPage from './pages/ContactUs';
import ForgotPasswordPage from './pages/ForgotPassword';
import HowItWorksPage from './pages/HowItWorks';
import LoginPage from './pages/Login';
import PricingPage from './pages/Pricing2';
import ProjectsDashBoard from './pages/Projects/ProjectPage';
import RevisionsPage from './pages/Revisions/RevisionPage';
import EditUserPage from './pages/UserEdit/EditUser';


// Composants avec lazy loading pour les fonctionnalités moins critiques
const NewProjectPage = lazy(() => import('./pages/Projects/NewProject'))
const EditProjectPage = lazy(() => import('./pages/Projects/EditProject'))
;

const NoMatchPage = lazy(() => import('./pages/NoMatch'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPassword'));
const SignupPage = SignUP;
const RevisionResultPage = lazy(() => import('./pages/Revisions/RevisionResult'))
const AddRevisionPage =lazy(() => import('./pages/Revisions/NewRevision'))
const EditRevisionPage =lazy(() => import('./pages/Revisions/EditRevision'));
const EditRevisionTicketDSBPage = lazy(() => import('./pages/Revisions/EditTicketDsb'))
const AdminTicketsPage = lazy(() => import('./pages/Admin/AdminTickets'));
const AdminOpenTicketPage = lazy(() => import('./pages/Admin/ticket_resolution/AdminOpenTicketPage'))

export const getRoutes = (role?: string): RouteObject[] => {
  return role && role.toLowerCase() === USER_ROLES.ADMIN
    ? [...AdminUserRoutes, ...publicRoutes]
    : [...AuthUserRoutes, ...publicRoutes];
};

export const publicRoutes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: '/login',
        element: <AuthenticationLayout page='login' />,
        children: [
          { index: true, element: <LoginPage /> },
          { path: '*', element: <LoginPage /> },
        ],
      },
      {
        path: '/forgot',
        element: <AuthenticationLayout page='forgot' />,
        children: [
          { index: true, element: <ForgotPasswordPage /> },
          { path: '*', element: <ForgotPasswordPage /> },
        ],
      },
      {
        path: '/reset/FORGOT/:token',
        element: <AuthenticationLayout page='reset' />,
        children: [
          { index: true, element: <ResetPasswordPage /> },
          { path: '*', element: <ResetPasswordPage /> },
        ],
      },
      {
        path: '/admin/login',
        element: <AuthenticationLayout page='adminLogin' />,
        children: [
          { index: true, element: <AdminLoginPage /> },
          { path: '*', element: <AdminLoginPage /> },
        ],
      },
      {
        path: '/signup',
        element: <Layout />,
        children: [
          { index: true, element: <SignupPage /> },
          { path: '*', element: <SignupPage /> },
        ],
      },
      {
        path: '/contact',
        element: <Layout />,
        children: [
          { index: true, element: <ContactUsPage /> },
          { path: '*', element: <ContactUsPage /> },
        ],
      },
      {
        path: '/how-it-works',
        element: <Layout />,
        children: [
          { index: true, element: <HowItWorksPage /> },
          { path: '*', element: <HowItWorksPage /> },
        ],
      },
      {
        path: '/pricing',
        element: <Layout />,
        children: [
          { index: true, element: <PricingPage /> },
          { path: '*', element: <PricingPage /> },
        ],
      },
      {
        path: '/invite/gate/:invitationDetails',
        element: <Layout />,
        children: [
          { index: true, element: <InviteSignUp /> },
          { path: '*', element: <InviteSignUp /> },
        ],
      },
      {
        path: '/signUpByInvite',
        element: <Layout />,
        children: [
          { index: true, element: <SignUpByInvite /> },
          { path: '*', element: <SignUpByInvite /> },
        ],
      },
    ],
  },
];

export const AuthUserRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <ProjectsDashBoard />
          </ProtectedRoute>
        ),
      },
      {
        path: '/add-project',
        element: (
          <ProtectedRoute>
            <NewProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit-project/:projectId',
        element: (
          <ProtectedRoute>
            <EditProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit/business',
        element: (
          <ProtectedRoute>
            <EditBusinessPage mode={"edit"} />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit/user',
        element: (
          <ProtectedRoute>
            <EditUserPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/project/:projectId/board',
        element: (
          <ProtectedRoute>
            <RevisionsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/project/:projectId/add-revision',
        element: (
          <ProtectedRoute>
            <AddRevisionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/business/:businessId/project/:projectId/edit-revision/:revisionId',
        element: (
          <ProtectedRoute>
            <EditRevisionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/business/:businessId/project/:projectId/revision/:revisionId/result',
        element: (
          <ProtectedRoute>
            <RevisionResultPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/project/:projectId/edit-ticket-dsb/:revisionId',
        element: (
          <ProtectedRoute>
            <EditRevisionTicketDSBPage />
          </ProtectedRoute>
        ),
      },
      { path: '*', element: <NoMatchPage /> },
    ],
  },
];

export const AdminUserRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/dashboard',
        element: (
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/tickets',
        element: (
          <ProtectedRoute>
            <AdminTicketsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/business/:businessId/projects',
        element: (
          <ProtectedRoute>
            {/* <AdminBusinessProjects /> */}
            <ProjectsDashBoard />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit-project/:projectId/:businessId',
        element: (
          <ProtectedRoute>
            <EditProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/edit/business/:businessId',
        element: (
          <ProtectedRoute>
            <BusinessAccountPage mode={"edit"} />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/add-business',
        element: (
          <ProtectedRoute>
            <BusinessAccountPage mode={"create"} />
          </ProtectedRoute>
        ),
      },
      {
        path: '/add-project/:businessId',
        element: (
          <ProtectedRoute>
            <NewProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/business/:businessId/project/:projectId/board',
        element: (
          <ProtectedRoute>
            <RevisionsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/business/:businessId/project/:projectId/revision/:revisionId/result',
        element: (
          <ProtectedRoute>
            <RevisionResultPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/business/:businessId/project/:projectId/add-revision',
        element: (
          <ProtectedRoute>
            <AddRevisionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/business/:businessId/project/:projectId/edit-revision/:revisionId',
        element: (
          <ProtectedRoute>
            <EditRevisionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/business/:businessId/project/:projectId/edit-ticket-dsb/:revisionId',
        element: (
          <ProtectedRoute>
            <EditRevisionTicketDSBPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin/business/:businessId/project/:projectId/revision/:revisionId/:ticketId',
        element: (
          <ProtectedRoute>
            <AdminOpenTicketPage />
          </ProtectedRoute>
        ),
      },

      { path: '*', element: <NoMatchPage /> },
    ],
  },
];