import { Box, Grid, Link, Typography } from '@mui/material';
import BadgeDiscount from 'assets/BadgeDiscount';
import ButtonCustom from 'components/ButtonCustom';
import UseAuthContext from 'context/AuthContext';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getPricingPlans } from 'services/pricing-service';
import shortid from 'shortid';


const PricingPage = () => {
	const { user, isAuthenticated, organisationId } = useContext(UseAuthContext)
	const transactionId = shortid.generate()
	const navigate = useNavigate()
	const { data: pricing, isLoading } = useQuery({
		queryKey: ['pricingPlans'],
		queryFn: getPricingPlans,
	});


	if (isLoading || !pricing) {
		return <div>Loading...</div>;
	}

	return (
		<Box mt={"20px"} display={'flex'} gap={0} flexDirection={'column'} justifyContent={"center"} alignItems={"center"}>
			<Typography variant="h1" fontSize={"36px"} align="center" >
				חוסכים לכם זמן וכסף בכל פרויקט
			</Typography>
			<Typography fontSize={"16px"} width={"350px"} mt={"14px"} align="center" >
				 דוחות אנרגיה מוסמכים, סימולציות ממוחשבות בענן 24/7
				דוחות דירוג אנרגטי ללא הגבלה למבנה*
			</Typography>
			<Grid mt={"28px"} container
				gap={2} justifyContent="center" sx={{ width: { xs: "50%", md: "105%" } }}>
				{pricing.data.filter((item) => item.name !== "Single Credit").map((plan, index) => (
					<Box display={'flex'} gap={"0px"} mt={""} key={plan.name} sx={{ border: plan.name === "Best" ? '2px solid #0F65EF' : '1px solid #C6CCD4', textAlign: 'center', borderRadius: "10px", position: 'relative' }} component="div" justifyContent={"center"} width={"377px"} height={"532px"}>
						{plan.name === "Best" && (
							<Box sx={{ position: 'absolute', top: '0px', left: '20px' }}>
								<BadgeDiscount />
							</Box>
						)}
						<Box display={"flex"} flexDirection={"column"} width={"258px"} >
							<Box mt={"30px"} >
								<Typography fontWeight={"bold"} variant={'h1'} sx={{ color: plan.name === "Best" ? '' : 'black' }}>{plan.name}</Typography>

								<Typography mt={"20px"} fontSize={"16px"}>
									<span style={{ fontSize: "34px", fontWeight: "600" }}>{plan.pricing.monthly} </span> ₪ לחודש
								</Typography>
								<Typography mt={"-5px"} fontSize={"16px"} dir="ltr">בתשלום אחד של  {plan.pricing.annual} ₪ (לפני מע״מ)</Typography>
								<ButtonCustom
									sx={{ width: "258px", height: "50px", marginTop: "25px", fontWeight: "bold" }}
									variant={plan.name === "Best" ? "contained" : "outlined"}
									color="primary"
									onClick={() => {
										if (isAuthenticated) {
											window.open(`${plan.payment_link}?ReturnData=${process.env.REACT_APP_ENV}|${organisationId}|${transactionId}|${user?.id}`, "_blank")
										}
										else {
											navigate("/login")
										}
									}}
								>
									רכישת {plan.credits} קרדיטים
								</ButtonCustom>

								{/* Table pour m2_pricing */}
								<Box
									sx={{
										marginTop: "20px",
										display: 'flex',
										flexDirection: 'column',
										width: '100%'
									}}
								>
									{/* Header */}
									<Grid container sx={{ borderBottom: "1px solid #C6CCD4", height: "41px" }}>
										<Grid item xs={4} sx={{
											fontWeight: '600',
											fontSize: "14px",
											textAlign: 'center',
											p: 0,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
											₪
										</Grid>
										<Grid item xs={4} sx={{
											fontWeight: '600',
											textAlign: 'center',
											p: 0,
											fontSize: "14px",

											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
											קרדיט
										</Grid>
										<Grid item xs={4} sx={{
											fontWeight: '600',
											textAlign: 'center',
											p: 0,
											fontSize: "14px",

											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
											מ״ר
										</Grid>
									</Grid>

									{/* Rows */}
									{plan.m2_pricing.map((m2, idx) => (
										<Grid container key={m2.m2_range} sx={{
											height: "41px",
											borderBottom: idx === plan.m2_pricing.length - 1 ? 'none' : '1px solid #C6CCD4' // Correction ici
										}}>
											<Grid item xs={4} sx={{
												textAlign: 'center',
												fontSize: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}}>
												{m2.Ils_Credit}
											</Grid>
											<Grid item xs={4} sx={{
												textAlign: 'center',
												fontSize: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}}>
												{m2.credits}
											</Grid>
											<Grid item xs={4} sx={{
												textAlign: 'center',
												fontSize: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}} dir='ltr'>
												{m2.m2_range}
											</Grid>
										</Grid>
									))}
								</Box>
							</Box>
							{/* <Box marginTop={"29px"}>
								<Typography 
								color={plan.name == "Best" ? '#0F65EF' : 'black'}
								 sx={{  fontWeight:"bold", fontSize:"16px" }}>עד {plan.buildings} מבנים סטנדרטים</Typography>

							</Box>
 */}
						</Box>
					</Box>
				))}
			</Grid>
			<Box display={'flex'} justifyContent={"center"} flexDirection={"column"} flexWrap={"wrap"} mt={"25px"} >
				<Typography fontSize={16} textAlign={"center"}>			*עם שמות, גאומטריה והעמדה זהים
					. ניתן גם לרכוש
					<span style={{ marginLeft: '4px', marginRight: '4px' }}>
						<Link
							sx={{ color: "blue", textDecoration: 'none', '&:hover': { textDecoration: 'none', color: 'blue' } }}
							href={`https://secure.cardcom.solutions/e/xr27?ReturnData=${process.env.REACT_APP_ENV}|${user?.organizationId}|${transactionId}|${user?.id}`}
							target="_blank"
							rel="noopener"
						>
							קרדיטים בודדים
						</Link>
					</span>
				</Typography>
				<Typography fontSize={"16px"} textAlign={"center"}>
					עד 12 תשלומים באמצעות כרטיס אשראי ללא ריבית או
					<span style={{ marginLeft: '4px', marginRight: '4px' }}>
						<Link
							sx={{ color: "blue", textDecoration: 'none', '&:hover': { textDecoration: 'none', color: 'blue' } }}
							 target="_blank" 
							 href="https://docs.google.com/forms/d/e/1FAIpQLScO0p3T9PzT02Fy_bPT1z7mVJ_94dEvLo_sxql85gL8zEUVJg/viewform"
							 rel="noopener">בהעברה בנקאית</Link>
					</span>
				</Typography>
			</Box>
			<Box display={'flex'} justifyContent={"space-around"} alignItems={"center"} mt={"20px"} width={"100%"} >
				<Box display={"flex"} gap={2} alignItems={"center"} width={"50%" } justifyContent={"flex-start"}>
					<img src="/static/img/favicons/cal.png" alt="CAL" style={{ height: "26px" }} />
					<img src="/static/img/favicons/isracard.png" alt="Isracard" style={{ height: "26px" }} />
					<img src="/static/img/favicons/leumi.png" alt="Leumi" style={{ height: "26px" }} />
					<img src="/static/img/favicons/mastercard.png" alt="Mastercard" style={{ height: "26px" }} />
					<img src="/static/img/favicons/visa.png" alt="Visa" style={{ height: "26px" }} />
				</Box>
				<Box display={"flex"} gap={2} alignItems={"center"} width={"50%"} justifyContent={"flex-end"} >
					<img src="/static/img/favicons/cardcom.png" alt="Cardcom" style={{ height: "60px" }} />
					<img src="/static/img/favicons/pci.png" alt="PCI" style={{ height: "30px" }} />
				</Box>

			</Box>
		</Box>
	);
};


export default PricingPage;