import { Box, useTheme } from '@mui/material';
import Parcoords from 'parcoord-es';
import 'parcoord-es/dist/parcoords.css';
import { useEffect, useRef, useState } from 'react';

let chartView = null
interface Dimension {
  type: string;
  title: string;
  order: number;
}


const Chart = props => {
  const { data, colorSet, mouseOverRow, updateBrushedData, brushedData, dimensions, sortChartAxis, isDimensionsHidden, reloadChart } = props

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([...data])
  const theme = useTheme();
  const handleReset = () => {
    reloadChart(); 
  };

  useEffect(() => {
    setChartData([...data])
    handleReset()
  }, [data,isDimensionsHidden])


  useEffect(() => {

    if (chartRef !== null && chartData !== null) {
      chartRef.current.innerHTML = ''
      let brushed = {}
      brushedData.map(brush => {
        if (brush.selection.scaled.length !== 0) {
          if (brush.selection.scaled.length === 1)
            brush.selection.scaled.push(brush.selection.scaled[0])
          brushed[brush.axis] = brush.selection.scaled.sort((a, b) => a - b)
        }
        return true
      })
      const isRTL = theme.direction === 'rtl';
      const orderedDimensions = isRTL
        ? Object.keys(dimensions).reverse().reduce((obj, key) => {
          obj[key] = dimensions[key];
          return obj;
        }, {})
        : dimensions;

      chartView = Parcoords()(chartRef.current)

        .data(chartData)
        .color((d, i) => {
          try {
            return colorSet[d.rating].color
          } catch (error) {
            return 'grey'
          }
        })
        .alpha(0.4)
        .mode("queue")
        .margin({
          top: 70,
          left: 0,
          right: 5,
          bottom: 16
        })
        .dimensions(orderedDimensions)
        .hideAxis(["id", ...isDimensionsHidden])
        .render()
        .reorderable()
        .createAxes()
        .brushMode("1D-axes")
        .brushExtents(brushed)
        .on("brush", function (d, e) {
          updateBrushedData(d, e)
        })

   
      setTimeout(() => {
        
        let texts = document.getElementsByClassName('label')
        for (let i = 0; i < texts.length; i++) {
          const element = texts[i] as SVGTextElement;
          element.style.pointerEvents = 'none';

          let fullText = element.getAttribute('label') || element.innerHTML;

          if (fullText.includes(':')) {
            element.textContent = '';

            const parts = fullText.split(':');
            const tspan1 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
            tspan1.textContent = parts[0] + ":"
            tspan1.setAttribute('x', '0');
            tspan1.setAttribute('dy', '-2.3em');
            element.appendChild(tspan1);

            const tspan2 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
            tspan2.textContent = parts[1] + ":"
            tspan2.setAttribute('x', '0');
            tspan2.setAttribute('dy', '1.2em');
            element.appendChild(tspan2);

            const tspan3 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
            tspan3.textContent = parts.slice(2,3).join(':')
            tspan3.setAttribute('x', '0');
            tspan3.setAttribute('dy', '1.2em');
            element.appendChild(tspan3);

            const tspan4 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
            tspan4.textContent = parts.slice(3).join(':')
            tspan4.setAttribute('x', '0');
            tspan4.setAttribute('dy', '1.2em');
            element.appendChild(tspan4);

            element.style.transition = 'transform 0.3s ease';
            const currentTransform = element.getAttribute('transform') || '';
            const newY = -28;
            element.setAttribute('transform', currentTransform.replace(/translate\(0,-?\d+\)/, `translate(0,${newY})`));
          } else {
            element.textContent = '';
            const tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
            tspan.textContent = fullText;
            tspan.setAttribute('x', '0');
            tspan.setAttribute('dy', '-0.5em');
            element.appendChild(tspan);

            element.style.transition = 'transform 0.3s ease';
            const currentTransform = element.getAttribute('transform') || '';
            const newY = -10;
            element.setAttribute('transform', currentTransform.replace(/translate\(0,-?\d+\)/, `translate(0,${newY})`));
          }
          //texts[i].addEventListener("dblclick", () => { if (i === 0) sortChartAxis(i) })
        }
      }, 0) 

    }
  }, [chartRef, chartData, colorSet, brushedData, dimensions, theme.direction])


  useEffect(() => {
    if (mouseOverRow !== null) {
      const item_id = mouseOverRow.id
      const d = chartData;
      const elementPos = d.map(function (x) { return x.id; }).indexOf(item_id);
      if (elementPos > -1) chartView.highlight([d[elementPos]]);
    } else {
      colorSet !== null && chartView.unhighlight();
    }
  }, [mouseOverRow])

  return (
    <Box dir='ltr'>
      <div ref={chartRef} id={'chart-id'} style={{ height: 500 }} className={'parcoords'} />
    </Box>
  );
}

export default Chart;