import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import AuthContext from 'context/AuthContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'utils/constants';
import { ellipsis } from 'utils/utils';
import ButtonIcon from './ButtonIcon';
import CustomTableCell from './CustomTableCell';
import GradeBadge from './GradeBadge';
import { StyledTableSortLabel } from './TableSortedLabel';
interface Column {
	id: string;
	visible: boolean;
	title: string;
	order: number;
	isEditable?: boolean; // Ajout de la propriété isEditable
}

interface LayersTableProps {
	tempschema: any[];
	openSettings: boolean;
	openResetDialog: boolean;
	columns: Column[];
	brushTempsData: string[];
	colorSet: { key: string; color: string }[];
	onRowHover: (layerId: number | null) => void;
	handleOpenSettings: () => void; // Nouvelle prop pour gérer l'ouverture des paramètres
	handleOpenResetDialog: () => void; // Nouvelle prop pour gérer l'ouverture des paramètres
	handleResetBrush: () => void; // Nouvelle prop pour gérer l'ouverture des paramètres
	onUpdateTempschema?: (updatedTempschema: any[]) => void; // Nouvelle prop pour mettre à jour tempschema
}

type Order = 'asc' | 'desc';

const DimensionTable: React.FC<LayersTableProps> = ({ tempschema, brushTempsData, openSettings, columns, colorSet, onRowHover, handleOpenSettings, handleOpenResetDialog, openResetDialog, onUpdateTempschema, handleResetBrush }) => {

	const { t } = useTranslation()
	const [selectedLayers, setSelectedLayers] = useState<number[]>([]);
	const [orderBy, setOrderBy] = useState<string>('idx');
	const [order, setOrder] = useState<Order>('desc');
	const [sortedTempschema, setSortedTempschema] = useState(tempschema);
	const [editingCell, setEditingCell] = useState<{ layerId: number, columnId: string } | null>(null);
	const { role } = useContext(AuthContext)
	
	useEffect(() => {
		if (brushTempsData.length > 0) {
			const updatedSortedTempschema = tempschema.filter(layer =>
				brushTempsData.includes(layer.id)
			);
			setSortedTempschema(updatedSortedTempschema);
		} else {
			// Si brushTempsData est vide, afficher toutes les données
			setSortedTempschema([...tempschema]);
		}
	}, [brushTempsData, tempschema]);

	const handleClick = (layerId: number) => {
		const selectedIndex = selectedLayers.indexOf(layerId);
		let newSelected: number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedLayers, layerId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedLayers.slice(1));
		} else if (selectedIndex === selectedLayers.length - 1) {
			newSelected = newSelected.concat(selectedLayers.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedLayers.slice(0, selectedIndex),
				selectedLayers.slice(selectedIndex + 1),
			);
		}

		setSelectedLayers(newSelected);
	};


	const handleSort = (property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);

		const sorted = [...sortedTempschema].sort((a, b) => {
			const aValue = a[property];
			const bValue = b[property];
			// Vérifier si la propriété est un nombre ou une chaîne
			if (typeof aValue === 'number' && typeof bValue === 'number') {
				return isAsc ? aValue - bValue : bValue - aValue;
			} else {
				// Gérer les valeurs null ou undefined
				const aStr = aValue != null ? String(aValue) : '';
				const bStr = bValue != null ? String(bValue) : '';
				return isAsc ? aStr.localeCompare(bStr) : bStr.localeCompare(aStr);
			}
		});
		setSortedTempschema(sorted);
	};

	const handleEditText = (layerId: number, columnId: string, value: string) => {
		const updatedTempschema = tempschema.map(layer =>
			layer.id === layerId ? { ...layer, [columnId]: value } : layer
		);
		setSortedTempschema(updatedTempschema);
		onUpdateTempschema?.(updatedTempschema);
	};

	const visibleColumns = columns.filter(column => column.visible);

	const getRatingLetter = (rating: number) => {
		return colorSet[rating]?.key || '';
	};

	const handleRowMouseEnter = useCallback((layer: any) => {
		onRowHover(layer);
	}, [onRowHover]);

	const handleRowMouseLeave = useCallback(() => {
		onRowHover(null);
	}, [onRowHover]);

	useEffect(() => {
		if (tempschema) {
			// Trier les données initiales par idx en ordre descendant
			const initialSorted = [...tempschema].sort((a, b) => {
				return b.idx - a.idx;
			});
			setSortedTempschema(initialSorted);
		}
	}, [tempschema]);

	return (
		<>
			{role !== USER_ROLES.VIEWER && (
				<Box display="flex" justifyContent="space-between" alignItems="center" >
					<Box style={{ display: "flex" }}>
						<Tooltip title={t('SETTINGS_LAYOUT')}>
							<ButtonIcon onClick={handleOpenSettings} isActive={openSettings}  >
								{/* 	<SettingsRoundedIcon /> */}
								<SettingsOutlinedIcon />
							</ButtonIcon>
						</Tooltip>
						{/* <Typography variant="h6" component="h2">
						Tableau des dimensions
					</Typography> */}
					</Box>
					<div>
						<Tooltip title={t('RESET_LAYOUT')} >
							<ButtonIcon isActive={openResetDialog} onClick={handleOpenResetDialog} >
								<ManageHistoryOutlinedIcon />
							</ButtonIcon>
						</Tooltip>
						{/* 	<Tooltip title={t('RESET_BRUSH')}>
						<IconButton onClick={handleResetBrush} >
							<RefreshIcon />
						</IconButton>
					</Tooltip> */}
					</div>
				</Box>
			)}
			<TableContainer
				component={Paper}
				sx={{
					'& .MuiTableCell-root': { p: 0.5 },
					//maxHeight: '400px', // hauteur maximale fixe
					overflow: 'auto',   // active le défilement
					flex: '1 1 auto',
					//minHeight: '200px'  // hauteur minimale
				}}
			>
				<Table size="small" >
					<TableHead >
						{visibleColumns.map((column) => (
							<CustomTableCell key={column.id}
								width={column.id === "idx" ? "30px" : column.id === 'rating' || column.id === 'weighed_grade' ? '70px' : '85px'}>
								{column.id == 'rating' ? (
									<Typography sx={{ cursor: "default" }} dir={"ltr"} variant='subtitle1' fontWeight="bold" title={column.title}>
										{ellipsis(column.title, 10)}
									</Typography>
								) : (
									<StyledTableSortLabel
										active={orderBy === column.id}
										direction={orderBy === column.id ? order : 'asc'}
										onClick={() => handleSort(column.id)}
									>
										<Typography dir={"ltr"} variant='subtitle1' fontWeight="bold" title={column.title}>
											{ellipsis(column.title, 10)}
										</Typography>
									</StyledTableSortLabel>
								)}
							</CustomTableCell>
						))}
					</TableHead>
					<TableBody>
						{sortedTempschema
							.map((layer) => (
								<TableRow
									key={layer.id}
									onClick={() => handleClick(layer.id)}
									onMouseEnter={() => handleRowMouseEnter(layer)}
									onMouseLeave={handleRowMouseLeave}
								>
									{visibleColumns.map((column) => (
										<CustomTableCell
											clickable={false}
											key={column.id}

										>
											{column.isEditable ? (
												<TextField
													value={layer[column.id] || ''}
													onChange={(e) => handleEditText(layer.id, column.id, e.target.value)}
													onBlur={() => setEditingCell(null)}
													onMouseEnter={() => setEditingCell({ layerId: layer.id, columnId: column.id })}
													variant="outlined"
													size="small"
													sx={{
														width: "70px",
														'& .MuiInputBase-input': {
															padding: '4px 8px',
														}
													}}
												/>
											) : column.id === 'name' ? (
												<Typography variant='caption' noWrap>
													{layer[column.id]}
												</Typography>
											) : column.id === 'rating' ? (
												<Typography variant='caption' noWrap dir={"ltr"} >
													<GradeBadge rating={getRatingLetter(layer[column.id])} weighed_grade={layer['weighed_grade']} />
												</Typography>
											) : column.id === 'weighed_grade' ? (
												<Typography variant='caption' dir={"ltr"} noWrap>
													{layer[column.id]}
												</Typography>
											) : (
												<Typography variant='caption' noWrap>
													{layer[column.id]}
												</Typography>
											)}
										</CustomTableCell>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default DimensionTable;