import axios from 'axios';
import { IErrorObject, IErrorResponse } from './contracts';
import { RemoveAllLoggedInUsersData } from './utils';

export const baseURL = process.env.NODE_ENV === 'development' ? `http://localhost:3000/api/v2` : `${process.env.REACT_APP_API_BASE_URL}/api/v2`
  

export const axiosInstance = axios.create({
  baseURL:baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});


axiosInstance.interceptors.request.use(
  (config: any) => {
    const stoken = localStorage.getItem('token');
    if (stoken) {
      config.headers['x-auth-token'] = stoken;
    }
    const sOrg = localStorage.getItem('organizationId');
    if (sOrg) {
      config.headers['x-organization-id'] = sOrg.toString();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    const errorObj: IErrorResponse = {};
    errorObj.actualError = error;
    if(error?.response?.data?.message === 'ACCESS_ORGANIZATION_DENIED'){
      RemoveAllLoggedInUsersData();
      window.location.href = '/login';
    }
    if (error?.response?.data) {
      errorObj.ServerErrorCode = [];
      Object.keys(error.response.data).map((key) => {
        errorObj?.ServerErrorCode?.push({
          field: key,
          code: error.response.data[key],
        } as IErrorObject);
      });
    } else {
      errorObj.GeneralErrorCode = 'GENERAL_ERROR';
    }

    return Promise.reject(errorObj);
  }
);


