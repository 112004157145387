import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonCustom from 'components/ButtonCustom';
import Spinner from 'components/Spinner';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPrivateAreaDetails } from 'services/private-area-services';
import { getErrorHtmlContent } from 'utils/utils';

const UserEmailEdit = ({ updatePrivateDetails }: any) => {
  const { t } = useTranslation();
  const [isLoading, setisLoading] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    isLoading: dataLoading,
    status,
    refetch: refetchPersonalDetails,
  } = useQuery('getPrivateAreaDetails', getPrivateAreaDetails);

  const [email, setEmail] = useState(data?.data?.email);
  useEffect(() => {
    setEmail(data?.data?.email);
  }, [data]);
  const handleClick = async (e: any) => {
    try {
      e.preventDefault();
      setisLoading(true);
      if (confirmEmail === newEmail) {
        await updatePrivateDetails({
          email: confirmEmail,
        });
        enqueueSnackbar(t('PRIVATE_AREA_EMAIL_UPDATE_SUCESS'), {
          variant: 'success',
        });
        await refetchPersonalDetails();
        setisLoading(false);
      } else {
        setisLoading(false);
        enqueueSnackbar(t('EDIT_BUSINESS_EMAIL_MISMATCH_ERROR'), {
          variant: 'error',
        });
      }
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
      setisLoading(false);
    }
  };

  if (dataLoading) {
    return <Spinner />;
  }

  return (
    <Box component='form' sx={{ mx: '2rem', display:"flex", gap:"30px",width:{xs:"100%", md:"480px"}  }} className='tab-content-form'>
      <TextField
        margin='normal'
        fullWidth
        variant='standard'
        id='email'
        name='email'
        type='email'
        autoFocus
        label={t('USER_EDIT_EMAIL_CURRENT_EMAIL')}
        autoComplete='off'
        autoCorrect='off'
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        helperText={t('USER_EDIT_EMAIL_CURRENT_EMAIL_HELPER_TEXT')}
        disabled
      />
      <TextField
        margin='normal'
        fullWidth
        variant='standard'
        id='new_email'
        name='new_email'
        type='email'
        label={t('USER_EDIT_EMAIL_NEW_EMAIL')}
        autoComplete='off'
        autoCorrect='off'
        helperText={t('USER_EDIT_EMAIL_NEW_EMAIL_HELPER_TEXT')}
        onChange={(e) => setNewEmail(e.target.value)}
        value={newEmail}
      />
      <TextField
        margin='normal'
        fullWidth
        variant='standard'
        id='new_email_confirmation'
        name='new_email_confirmation'
        type='email'
        label={t('USER_EDIT_EMAIL_NEW_EMAIL_CONFIRM')}
        autoComplete='off'
        autoCorrect='off'
        helperText={t('USER_EDIT_EMAIL_NEW_EMAIL_CONFIRM_HELPER_TEXT')}
        onChange={(e) => setConfirmEmail(e.target.value)}
        value={confirmEmail}
      />
      <ButtonCustom
        color='primary'
        type='submit'
        variant='contained'
        onClick={(e: any) => handleClick(e)}

      >
        {t('SAVE_BUTTON')}
      </ButtonCustom>
    </Box>
  );
};

export default UserEmailEdit;
